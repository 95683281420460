import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '@organisms/layout'
import Heading from '@particles/heading'
import SEO from '@atoms/seo'
import Link from '@atoms/link'

const SitemapPage = ({ data, location }) => {
  const pages = data.pages.edges

  /* Could do this kind of split thing maybe? To establish structure.
  const sortedPages = []
  pages.forEach(({ node }) => {
    var splitUrl = node.path.split('/')
    sortedPages.push({
      category: splitUrl[1],
      name: splitUrl[splitUrl.length - 2]
    })
  })
  */

  return (
    <Layout location={location}>
      <SEO title={`Sitemap`} location={location} />
      <Heading h1 margin="T2 B1.5">
        Sitemap
      </Heading>
      <Layout.Grid fixed={294} gap={1.25}>
        {pages.map(({ node }, i) => {
          return (
            <Link key={`page-${i}`} to={node.path}>
              {node.path}
            </Link>
          )
        })}
      </Layout.Grid>
    </Layout>
  )
}

SitemapPage.propTypes = {
  data: PropTypes.any,
  location: PropTypes.any
}
export default SitemapPage

export const pageQuery = graphql`
  query SitemapQuery {
    pages: allSitePage(sort: { fields: path, order: ASC }) {
      edges {
        node {
          path
        }
      }
    }
  }
`
